@import url(https://fonts.googleapis.com/css2?family=Anton&family=Patrick+Hand&family=Poppins:wght@100;400&family=Roboto&display=swap);

* {
    font-family: 'Poppins', sans-serif !important;
}


:root {
    --primaryColor: rgba(0, 0, 0, 0.7);
    --primaryFillColor: rgb(0, 0, 0,1);
    --textBlack: rgba(54, 54, 54, 1);
    --hoverPrimaryBtn: rgba(0, 0, 0);
    --formShadow: rgba(46, 50, 43, 0.25);
    --textSecondary: #3d3d3d;
}


html {
    overflow: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--hoverPrimaryBtn);
}

.pointer {
    cursor: pointer;
}

h1,h2,h3,h4,h5,h6{
    color: #364A63 !important;
}

.css-cveggr-MuiListItemIcon-root {
    min-width: 28px !important;
}

.css-1f8bwsm{
    min-width: 28px !important;
}

.css-1ygcj2i-MuiTableCell-root {
    font-weight: 600 !important;
    color: var(--textSecondary) !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    display: flex !important;
    align-items: baseline !important;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    z-index: 1045 !important;
}

.css-1e797rr-MuiPaper-root-MuiAppBar-root {
    z-index: 1045 !important;
}

.css-1l8j5k8 {
    z-index: 1045 !important;
}

.drawer {
    width: 450px !important;
}

.css-1yw475k {
    z-index: 1045 !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    border-radius: 5px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 7.2px !important;
    border-radius: 5px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    /* height: auto; */
    height: 25px !important;
    text-overflow: ellipsis;
    white-space: unset !important;
    overflow: hidden;
    max-height: 25px !important;
    font-size: 14px !important;
}

.css-jtlhu6-MuiTablePagination-root {
    overflow: hidden !important;
    color: rgba(0, 0, 0);
    font-size: 0.875rem;
}

.css-qiwgdb {
    padding: 7.5px 14px !important;
}

.activeTab {
    color: #7F8DFF !important;

    svg {
        color: #7F8DFF !important;
    }
}

.activeTab2 {
    border-bottom: 2px solid var(--primaryColor);
}

.transition-2 {
    transition: 0.3s all !important;
}


.table-container {
    width: 100%;
    overflow-x: auto;
    position: absolute;
    background: white;
    .table {
        width: 100%;
        min-width: 1000px !important;
    }
}

/* width */
.table-container::-webkit-scrollbar {
    height: 8px;
}

/* Track */
.table-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.table-container::-webkit-scrollbar-thumb {
    background: #b6b1b1;
}

/* Handle on hover */
.table-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}



.f-11 {
    font-size: 11px !important;
}

.f-12 {
    font-size: 12px !important;
}

.f-13 {
    font-size: 0.8125rem !important;
}

.f-14 {
    font-size: 0.875rem !important;
}

.f-15 {
    font-size: 15px !important;
}

/* font weight start  */

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

/* font weight end  */


/* buttons style start  */

.btn-radius{
    border-radius: 20px !important;
}

.btn-primary {
    /* background: var(--primaryColor) !important;
    border-color: var(--primaryColor) !important; */
    color: white !important;
}

.btn-primary:hover {
    /* background: var(--hoverPrimaryBtn) !important;
    border-color: var(--hoverPrimaryBtn) !important; */
    color: white !important;
}

/* buttons style end  */


/* text style start  */



.text-primary {
    color: var(--primaryColor) !important;
}

.text-secondary {
    color: var(--textSecondary) !important;
}

.text-gray {
    color: gray !important;
}

.text-black {
    color: var(--textBlack);
}

.text-white {
    color: white !important;
}

/* text style end  */




/* form style start  */

.form-check-input:checked {
    background-color: var(--primaryColor) !important;
    border-color: var(--primaryColor) !important;
}

input[type='checked']:checked {
    background-color: var(--primaryColor) !important;
    border-color: var(--primaryColor) !important;
}

.form-check-input:focus {
    border-color: var(--primaryColor) !important;
    outline: 0 !;
    box-shadow: 0 0 0 .25rem var(--formShadow) !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus {
    border-color: var(--primaryColor) !important;
    outline: 0 !important;
    box-shadow: 0 0 0 .25rem var(--formShadow) !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: var(--primaryColor) !important;
}

.form-control:focus {
    border-color: var(--primaryColor) !important;
    outline: 0;
    box-shadow: 0 0 0 .25rem var(--formShadow) !important;
}

.form-select:focus {
    border-color: var(--primaryColor) !important;
    outline: 0;
    box-shadow: 0 0 0 .25rem var(--formShadow) !important;
}

select option:hover {

    background: var(--primaryColor) !important;
}

/* form style end  */


.p-relative {
    position: relative;
}

.pendingStatus {
    color: black;
    background: rgba(255, 255, 0, 0.2);
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 10px;
}

.rejectedStatus {
    color: red;
    background: rgba(255, 0, 0, 0.2);
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 10px;
}

.approvedStatus {
    color: green;
    background: rgba(0, 128, 0, 0.2);
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 10px;
}


.p-treetable-table {

    td {
        border-bottom: 1px solid rgb(231, 227, 227) !important;
    }
}


/* .p-treetable-header tr{
  border: 1px solid lightgray !important;

} */
.style_main_sec__19Qlf {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/static/media/signupbg.cceb51ae.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.style_right__KH4X- {
  width: 100%;
  max-width: 600px;
  border: 1px solid gray;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.664);
  padding: 35px;
}

.style_inner__2RBf- {
  width: 60%;
}

.style_main-img__2xAmO {
  size: cover;
}

@media only screen and (max-width: 700px) {
  .style_main_sec__19Qlf {
    display: block;
  }

  .style_left__39nO9 {
    /* height: 40vh; */
    display: none;
  }
  .style_right__KH4X- {
    width: 100%;
  }

  .style_inner__2RBf- {
    width: 80%;
  }
}

.style_mainContainer__3hoKH {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #f5f6fa;
}

.style_topbar__26OwI {
    width: 100%;
    height: 64px;
}

.style_content__14fRh {
    width: 100%;
    height: calc(100vh - 64px);
    overflow-y: auto;
    overflow-x: hidden;
}
.style_profileContainer__3NxyE {
    border: 3px solid var(--primaryColor);
    width: 160px;
    height: 160px;
    border-radius: 100%;
    overflow: hidden;
    /* box-shadow: inset 0px 0px 0px 7px var(--primaryColor); */
    z-index: 2;
}

.style_camera__2XKM7 {
    width: 25px;
    height: 25px;
    background: var(--primaryFillColor);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
}

.style_tabContainer__1IDzX {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.style_left__mlCzT {
    width: 230px;
    border-right: 1px solid lightgray;
    padding: 20px 10px;
    min-height: 60vh;
    overflow-x: hidden;
}

.style_right__1bTek {
    width: calc(100% - 230px);
    /* padding: 30px; */
}

.style_docContainer__TGWik {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.style_DCard__thglh {
    box-shadow: 0px 4px 4px 0px #0000000D;
    border-radius: 15px;
    padding: 25px;
    background: linear-gradient(180deg, rgba(255, 199, 181, 0.3) 0%, rgba(255, 183, 199, 0.3) 52.49%, rgba(255, 179, 204, 0.3) 100%);
    position: relative;
    overflow: hidden;
}

.style_dUpper__oNxBp {
    height: 140px;
}


.style_dbottom__2n1Cv {
    width: 100%;
    height: 60px;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    background: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.style_filetype__3uxuI {
    width: 30px;
    margin-right: 10px;
}



@media screen and (max-width: 767px) {
    .style_tabContainer__1IDzX {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .style_left__mlCzT {
        width: 100%;
        border-right: none;
        padding: 0;
        min-height: unset;
        overflow-x: auto;
    }

    .style_right__1bTek {
        width: 100%;
    }

    .style_docContainer__TGWik {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 15px;
        gap: 15px;
    }

    .style_dUpper__oNxBp {
        height: 120px;
    }

}

@media screen and (max-width: 576px) {
    .style_left__mlCzT {
        font-size: 14px;
    }

    .style_profileContainer__3NxyE {
        border: 3px solid var(--primaryColor);
        width: 100px;
        height: 100px;
        border-radius: 100%;
        overflow: hidden;
        /* box-shadow: inset 0px 0px 0px 7px var(--primaryColor); */
        z-index: 2;
    }

    .style_camera__2XKM7 {
        width: 25px;
        height: 25px;
        background: var(--primaryFillColor);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        position: absolute;
        right: 5px;
        bottom: 0px;
        cursor: pointer;
    }
}
.Dropdownfordeviceid_button_dropdown__1eZhQ {
    cursor: pointer;
}

.Dropdownfordeviceid_uolist_style__x3B9n {
    list-style: none;
    padding: 0;
    margin-top: 5px;
    padding: 1% !important;
    margin-bottom: 0;
}

.Dropdownfordeviceid_list_style__MxPK_ {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
}


.Dropdownfordeviceid_button_options__1zIYw {
    background: black;
    color: white;
    border-radius: 3px;
    border: none;
    display: flex;
    align-items: center;
}


.Dropdownfordeviceid_button_options_one__opQ2k {
    background: transparent;
    border-radius: 3px;
    border: none;
    display: flex;
    align-items: center;
}

.Dropdownfordeviceid_add_id_button__2ouzK {
    color: black;
    background: white;
    font-family: Avenir Next LT Pro;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    padding: 6px 12px 6px 12px;
    border-radius: 10px;
    width: unset;
    box-shadow: 1px 1px 4px rgb(7, 7, 7);
    border: none;
    margin-top: 0.5rem;
}
.Uselisting_search_box__3ZmFA {
  width: 20%;
  height: 35px;
  border-radius: 5px;
  padding: 10px;

}

.Uselisting_userlisting__1AzSy {
  min-height: 350px;
  max-height: 350px;
  overflow-y: auto;
  /* margin:42px; */
  margin-top: 0px;
}

.Uselisting_modal_content__2ql6Q {
  padding: 32px !important;
  border-radius: 24px !important;
}




.Uselisting_modal_content_1__1CA_T {
  width: 485px !important;
  /* height: 258px !important; */
  border-radius: 24px !important;

}

.Uselisting_headerbutton__mdm3a:target {
  background: pink !important;
  /* color: red; */
}


.Uselisting_bold_char__2munE {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.Uselisting_bold_char_themes__rgxba {
  font-weight: 700 !important;
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.Uselisting_details_field__2GosM {
  text-align: start;
  width: 50% !important;
}

.Uselisting_value_field__3yd27 {
  font-weight: normal;
}

.Uselisting_data_section__3wJ5L {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding-right: 20px;
}


.Uselisting_data_section__3wJ5L>div>div:first-child {
  font-weight: 500;
}

.Uselisting_data_section__3wJ5L {
  & div :first-child {
    width: 200px;
  }

}





.Uselisting_header_data__28_l5 {
  display: flex;
  justify-content: space-between;
  /* margin: 32px; */
  margin-bottom: 25;
}

.Uselisting_header_text__379zX {
  /* font-family: Inter; */
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

}

.Uselisting_cross_button__238VK {
  border-radius: 50%;
  background-color: #ffffff;
  padding: 5px;
  color: #0a0a0a;
  cursor: pointer;
  box-shadow: 2px 2px 2px gray;
}

.Uselisting_cross_button_for_id_proof__2vpJl {
  border-radius: 50%;
  background-color: #FDF5F7;
  padding: 5px;
  color: #E03368;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}

.Uselisting_nav_main__2sotk {
  margin-top: 20px;
}


.Uselisting_headerbutton__mdm3a {
  /* font-family: Lexend; */
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

  cursor: pointer;

  color: #1F1216;
}


.Uselisting_view_more__1eH_G {
  border: none !important;
  background-color: #000000;
  border-radius: 3px;
  color: white;
}

.Uselisting_navitem__4oD1I {
  border: none !important;
  background-color: transparent !important;
}

.Uselisting_navitem__4oD1I:focus {
  color: #E03368;

}

.Uselisting_navitem__4oD1I.Uselisting_focused__2Z5lv {
  color: #E03368;
}

.Uselisting_navitem__4oD1I:hover {
  background-color: transparent !important;
}



.Uselisting_NavText__2wUsq {
  font-size: 15px;
  font-weight: 600;
  color: #0a0a0a !important;

}

.Uselisting_NavText__2wUsq:hover {
  color: rgb(17, 16, 16) !important;
}

.Uselisting_NavTab__1vhd7 {
  background: none !important;
  border: none;
}

.Uselisting_text_cap__3vhO_ {
  font-weight: normal;
}



.Uselisting_border_1__3ZvHL {
  border: 1px solid rgb(211, 208, 208);
  border-bottom: none !important;
  border-top: none !important;
}



.Uselisting_heading_section__2Ydgz {
  margin: 16px;
  /* padding-top: 20px; */
  align-items: center;
  justify-content: space-between;
}

.Uselisting_searchbar__3btJP {
  border-radius: 12px;
  background-color: rgb(243, 241, 241);
  width: 350px;
  border: 1px solid #5d5d5d;
  height: 42px;
  background: transparent;
}

.Uselisting_searchbar__3btJP::placeholder {
  color: #5d5d5d;
  font-family: Avenir Next LT Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Uselisting_delete_button__I1Ocs {
  /* border-radius: 12px; */
  /* border: 0.5px solid #5d5d5d; */
  border: none;
  background: none;
  font-size: 38px;
  font-weight: 400;
  line-height: 38px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 12px 13px; */
}

.Uselisting_delete_button__I1Ocs:hover {
  background: transparent !important;
}

.Uselisting_custom_margin__2GTJZ {
  margin-right: 20px;
}


.Uselisting_form-check__1W7VI {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-end !important;
  margin: 0px !important;
}

.Uselisting_delete_icon__2nqha {
  background-color: pink;
}


.Uselisting_no_style__2q8LV:hover {
  background-color: rgb(0, 0, 0);
  /* color:red; */
}

.Uselisting_image_size__20KEj {
  height: 200px;
  width: 200px;
  /* object-fit: cover; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;

}


.Uselisting_highlighted_delete__10aku {
  background-color: rgb(234, 143, 158);
  color: #fdf5f7;

}


.Uselisting_delete_modal_text__kDjqM {
  text-align: left;
  margin-bottom: 24px;

  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 140% */
  letter-spacing: 0.28px;

}

.Uselisting_delete_modal_text_1__PN4Ww {
  color: #211F1F;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.224px;
  margin-bottom: 22px;
}

.Uselisting_delete_buttons_section__1a5aA {
  display: flex;
  justify-content: flex-end;
  grid-gap: 16px;
  gap: 16px;
}

.Uselisting_cancel_button_of_delete_section__gkfr6 {
  width: 158px;
  height: 52px;
  /* background: linear-gradient(to right, #FF84A7, #E03368); */

  /* color:white; */
  padding: 16px 32px;
  border-radius: 10px;

  text-align: center;
  cursor: pointer;
  box-shadow: 3px 3px 7px 3px #888888;
}

.Uselisting_cancel_button_of_delete_section__gkfr6:hover {
  /* background: linear-gradient(to right, #FF84A7, #E03368); */
  background: black;
  color: white;
  box-shadow: -3px 3px 7px 3px #888888;
}



.Uselisting_delete_button_of_delete_section__qzvl3 {
  width: 158px;
  height: 52px;
  /* background-color: #FAE4EB; */
  padding: 16px 32px;
  border-radius: 10px;
  /* color: #E03368; */
  text-align: center;
  cursor: pointer;
  box-shadow: 3px 3px 7px 3px #888888;
}

.Uselisting_delete_button_of_delete_section__qzvl3:hover {
  box-shadow: 3px 3px 7px 3px #888888;
  color: white;
  background: black;
}

.Uselisting_textBold__2irmV {
  font-weight: 600 !important;
}

.Uselisting_details_field1__v4GnY {
  font-weight: 600 !important;
  text-align: start;
  width: 50% !important;
}





.AddTheme_button_background0__3ulYQ {
  background: pink !important;
  border: none !important;
  padding: 3px 20px;
  border-radius: 10px;
  color: black !important;
  font-weight: 600 !important;
}

.AddTheme_button_background0__3ulYQ :hover {
  background: none !important;

}

.AddTheme_button_background1__7JMHe {
  background: pink !important;
  border: none !important;
  padding: 3px 20px;
  margin-right: 20px !important;
  border-radius: 10px;
  color: black !important;
  height: 45px;
  font-weight: 600;
}

.AddTheme_button_background1__7JMHe :hover {
  background: none !important;

}

.AddTheme_inputIcon__2O6vt {
  position: relative;
}

.AddTheme_IconArrow__1I8GY {
  position: absolute;
  bottom: 5px;
  left: 90%;
  font-size: 15px;
}

.AddTheme_IconAdd__1ef-c {
  position: absolute;
  top: 45px;
  right: 20px;
  color: rgb(248, 57, 88);
}

.AddTheme_IconAdd1__3XdSX {
  position: absolute;
  top: 13px;
  right: 20px;
  color: rgb(248, 57, 88);
}

.AddTheme_ImageArrow__3e4eJ {
  color: rgb(248, 57, 88);
  /* background-color: #ffa5c0; */
}

.AddTheme_headingback__3Js68 {
  color: rgb(248, 57, 88);
  background-color: #E033681A;
  padding: 5px 12px;
  border-radius: 16px;
}

.AddTheme_labelImge__2s2d9 {
  color: rgb(248, 57, 88);
  font-size: 12px;
  font-weight: 300;
}

.AddTheme_iconimage__2OqZ8 {
  color: rgb(248, 57, 88);
}

.AddTheme_DeleteButton__2gvFt {
  border-radius: 10px;
  background-color: rgba(224, 51, 104, 0.15);
  border: none;
  font-size: 18px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddTheme_highlighted-delete__1itZt {
  background-color: #ffa5c0;
  color: #fdf5f7;
  border: 2px solid rgb(242, 156, 156);
}

.AddTheme_Viewbutton__TZ7v7 {
  background-color: #E03368;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 300;
  padding: 6px, 10px, 6px, 8px;
  color: white;
}

.AddTheme_imagehaeding__IZuzy {
  color: rgb(248, 57, 88);
}

.AddTheme_EditButton__3mFW- {
  background-color: #E03368;
  border: none;
  border-radius: 10px;
  color: white;
}

.AddTheme_Intrestname__1WjuU {
  /* background-color: #FAE4EB; */
  color: hsl(343, 81%, 58%);

  border-radius: 16px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
  /* gap: 8px; */
  padding: 4px;
}

.AddTheme_ModalDlt1__2Sx9z {
  color: #211F1F;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.224px;
  margin-bottom: 22px;
}

.AddTheme_ModalDlt__RfaEF {
  color: #211F1F;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.224px;
  margin-bottom: 22px;
}

.AddTheme_DelteButton___2kSQ {
  width: 158px;
  height: 52px;
  background-color: #FAE4EB;
  padding: 16px 32px;
  border-radius: 10px;
  color: #E03368;
  text-align: center;
  cursor: pointer;
}
.AddTheme_DelteButton___2kSQ:hover{
  box-shadow:3px 3px 7px 3px #888888;
}

.AddTheme_Allbutton__2gPLd {
  display: flex;
  justify-content: flex-end;
  grid-gap: 16px;
  gap: 16px;
}

.AddTheme_CencelButton__1EV1C {
  width:158px;
  height: 52px;
  background: linear-gradient(to right, #FF84A7, #E03368);
  padding:16px 32px;
  border-radius: 10px;
  color:white;
  text-align: center;
  cursor: pointer;
}
.AddTheme_CencelButton__1EV1C:hover{
  background: linear-gradient(to right, #FF84A7, #E03368);
  box-shadow: -3px 3px 7px 3px #888888;
}

.AddTheme_ImgaeSize__3WMDo {
  width: 74px;
  height: 62px;
}

.AddTheme_ImageIcon__1fZGi {
  width: 60px;
  height: 60px;
}

.AddTheme_ImgaeSize1__1P4XY {
  width: 100%;
  height: 130px;
}

.AddTheme_ImgesModal__1h1qJ {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  gap: 10px;
}

.AddTheme_Modal-content__PucWI {
  overflow: hidden;
}

.AddTheme_Crousel__AOWQD {
  width: 100%;
  height: 200px;
  /* border-radius: 10px 10px 0px 0px; */
}

.AddTheme_themeName__EsNg5 {
  font-size: 20px;
  font-weight: 600;
  color: #1B1919;

}

.AddTheme_IconSize__eA39n {
  width: 28px;
  height: 28px;

}

.AddTheme_IntrestTab__m3Sg9 {
  background-color: #f8d7e1;
  color: #FF84A7;
  font-size: 16px;
  font-weight: 300;
  border-radius: 16px;
  padding: 4px 9px !important;
}

.AddTheme_ThemeName__39Xz2 {
  font-size: 20px;
  font-weight: 400;
  color: #211F1F;
}

.AddTheme_Buttoncarusel__fYKL1{
width: 30px !important;
}
.AddTheme_Buttoncarusel__fYKL1:hover {
  background: transparent;
}

.AddTheme_InterestSize__k-URC {
  width: 30% !important;
}


.AddTheme_parentIntrest__3LOul {
  position: relative;
}

.AddTheme_NameIntrest__2knrJ {
  position: absolute;
  bottom: 1%;
  justify-content: center;
  /* padding: 5px 30px; */
  align-items: center;
  text-align: center;
  color: white;
  font-size: 20px;
  right: 50%;
}

.AddTheme_InterestSize1__wRI7l {
  width: 200px !important;
}

.AddTheme_DltCustom__24DOs {
  padding: 0 15px;
  overflow: hidden;
  color: #E03368;
}

.AddTheme_headingback1__27K65 {
  /* color: rgb(248, 57, 88); */
  /* background-color: #E033681A; */
  color: black;
  /* padding: 5px 15px; */
  /* border-radius: 16px; */
}

.AddTheme_DltCustom__24DOs :hover {
  background: #f8d7e1;
  ;
}

.AddTheme_Backgroundimges__cxYTm {
  position: absolute;
  /* bottom: 99%; */
  top: 0%;
  left: 4%;
}

.AddTheme_Backgroundimges1__1tgQa {
  position: absolute;
  bottom: 90%;
  left: 90%;
}

.AddTheme_themeName1__NCL3X {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
}
.Eventmanagement_image_size__3yVT8 {
  height: 100px;
  width: 120px;
}
.Eventmanagement_tableEventRow__2fZYc {
  font-size: 20px !important;
  font-weight: 500;
}
.Eventmanagement_ThemeEvent__3CQCd {
  width: 150px !important;
  /* font-size: 18px; */
}
.Eventmanagement_ThemeEvent1__mZGQN {
  width: 200px !important;
  /* font-size: 18px; */
}
.Eventmanagement_labelImge__3bUXX {
  color: rgb(248, 57, 88);
}

.Eventmanagement_DeleteButoonEvent__CHcKR {
  border-radius: 10px;
  background-color: rgba(224, 51, 104, 0.15);
  border: none;
  font-size: 18px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Eventmanagement_highlighted_delete__1qWYy {
  background-color: #ffa5c0;
  color: #fdf5f7;
  border: 2px solid rgb(242, 156, 156);
}


.Eventmanagement_delete_modal_text__2dWBL{
  text-align: left;
  margin-bottom: 24px;
  
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: 0.28px;
  
  }
  

.Eventmanagement_button_background0__22znJ {
  background: pink !important;
  border: none !important;
  padding: 3px 20px;
  border-radius: 10px;
  color: black !important;
  font-weight: 600 !important;
}
.Eventmanagement_button_background0__22znJ :hover {
  background: none !important;
}

.Eventmanagement_button_background1__3QNUp {
  background: pink !important;
  border: none !important;
  padding: 3px 20px;
  margin-right: 20px;
  border-radius: 10px;
  color: black !important;
  height: 45px;
  font-weight: 600;
}
.Eventmanagement_button_background1__3QNUp :hover {
  background: none !important;
}

.Eventmanagement_DeleteButton__2ZQVP {
  border-radius: 10px;
  background-color: rgba(224, 51, 104, 0.15);
  border: none;
  font-size: 18px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Eventmanagement_highlighted-delete__VG50t {
  background-color: #ffa5c0;
  color: #fdf5f7;
  border: 2px solid rgb(242, 156, 156);
}


.Eventmanagement_delete_button_of_delete_section__3NR5P:hover{
  box-shadow:3px 3px 7px 3px #888888;
}



.Eventmanagement_Viewbutton__NXyIL {
  background-color: #e03368;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 300;
  padding: 6px, 10px, 6px, 8px;
  color: white;
}
.Eventmanagement_imagehaeding__3-Fyv {
  color: rgb(248, 57, 88);
}

.Eventmanagement_EditButton__1iTMC {
  background-color: #e03368;
  border: none;
  border-radius: 10px;
  color: white;
}


.Eventmanagement_DelteButton__1G2Pl {
  width: 158px;
  height: 52px;
  background-color: #fae4eb;
  padding: 16px 32px;
  border-radius: 10px;
  color: #e03368;
  text-align: center;
  cursor: pointer;
}

.Eventmanagement_Allbutton__2lvqU {
  display: flex;
  justify-content: flex-end;
  grid-gap: 16px;
  gap: 16px;
}

.Eventmanagement_CencelButton__rFx4v {
  width: 158px;
  height: 52px;
  background: linear-gradient(to right, #ff84a7, #e03368);
  padding: 16px 32px;
  border-radius: 10px;
  color: white;
  text-align: center;
  cursor: pointer;
}

.Eventmanagement_theme_event_custom__cXz2y {
  width: 20% !important;
}

.Eventmanagement_theme_event_cost__bJqts {
  width: 7% !important;
}

.Eventmanagement_theme_event_location__wgu-j {
  width: 7% !important;
}

/* view section */

.Eventmanagement_viewmodal__33gMN {
  max-height: 100%;
  max-width: 100%;
}

/* .header_of_modal {
  height: 223px;
  width: 100%;
  padding: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.42rem 0.42rem 0 0;
} */

.Eventmanagement_header_of_modal__1WAa_ {
  height: 223px;
  width: 100%;
  padding: 25px;
  background-position: center;
  background-repeat: no-repeat !important; /* Use !important to ensure it takes precedence */
  background-size: cover !important;
  border-radius: 0.42rem 0.42rem 0 0;
}
.Eventmanagement_modal_size__3K2dp{
  padding: 24px;
}

.Eventmanagement_header_data__HCHol {
  display: flex;
  justify-content: space-between;
}
.Eventmanagement_cross_button__1RFbn {
  border-radius: 50%;
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
}

.Eventmanagement_header_text__3aiAi {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.Eventmanagement_name_and_theme_of_cafe__1Fcan {
  display: flex;
  flex-direction: column;

  width: 85% !important;
  padding: 12px 20px 12px 20px;
  border-radius: 14px;
  grid-gap: 12px;
  gap: 12px;
  background: rgb(255, 255, 255);
  position: relative;
  bottom: 30px;
  box-shadow: 1px 1px 3px 0.3px #989394 inset;
}


  .Eventmanagement_name_and_theme_of_cafe_1__2WBqf{
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    
  }

  .Eventmanagement_name_and_theme_of_cafe_2__3BOu_{
      color:#989394;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;

  }

  .Eventmanagement_name_and_theme_of_cafe_2__3BOu_ :first-child{
    color:#ff84a7;
  }

  .Eventmanagement_name_and_theme_of_cafe_3__2kqia :first-child{
    height: 20px;
    width: 16px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .Eventmanagement_name_and_theme_of_cafe_3__2kqia{
      color:#f1608b;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;

  }





.Eventmanagement_content_section_of_view_modal__1IHki {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Eventmanagement_description_of_view__2geXO{
  width:82%;
}


  .Eventmanagement_description_of_view_1__3B5pQ{
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
  }

  .Eventmanagement_description_of_view_2__3VaIR{

font-size: 15px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: justify;
color:#989394;

  }


.Eventmanagement_cost_of_view__Zfibv
{
  width:82%;
  margin-top:3%;
  margin-bottom: 5%;
}

  .Eventmanagement_cost_of_view_1__U-qhi{
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 5px;

  }
  .Eventmanagement_cost_of_view_2__JSQDO{
    color: #000;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; 

  }



/* Delete modal all styles ************************************************************************************************* */ 


.Eventmanagement_modal_content_1__3_OWJ{
  width: 485px !important;
  /* height: 258px !important; */
  border-radius: 24px !important;
  
}


.Eventmanagement_delete_modal_text__2dWBL{
  text-align: left;
  margin-bottom: 24px;
  
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: 0.28px;
  
  }

  
.Eventmanagement_delete_modal_text_1__2uEId{
  color: #211F1F;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.224px;
  margin-bottom: 22px;
}


.Eventmanagement_delete_buttons_section__3YbBj{
  display: flex;
  justify-content: flex-end;
  grid-gap: 16px;
  gap: 16px;
}

.Eventmanagement_cancel_button_of_delete_section__3s2Kk{
  width:158px;
  height: 52px;
  background: linear-gradient(to right, #FF84A7, #E03368);
  padding:16px 32px;
  border-radius: 10px;
  color:white;
  text-align: center;
  cursor: pointer;
}

.Eventmanagement_cancel_button_of_delete_section__3s2Kk:hover{
  background: linear-gradient(to right, #FF84A7, #E03368);
  box-shadow: -3px 3px 7px 3px #888888;
}



.Eventmanagement_delete_button_of_delete_section__3NR5P{
  width:158px;
  height: 52px;
  background-color: #FAE4EB;
  padding:16px 32px;
  border-radius: 10px;
  color: #E03368;
  text-align: center;
  cursor: pointer;
}

.Eventmanagement_delete_button_of_delete_section__3NR5P:hover{
  box-shadow:3px 3px 7px 3px #888888;
}

.style_profileContainer__3nd5a {
    border: 3px solid var(--primaryColor);
    width: 160px;
    height: 160px;
    border-radius: 100%;
    overflow: hidden;
    /* box-shadow: inset 0px 0px 0px 7px var(--primaryColor); */
    z-index: 2;
}

.style_camera__1mGQm {
    width: 25px;
    height: 25px;
    background: var(--primaryFillColor);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
}

.style_tabContainer__qpnzw {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.style_left__1FBL8 {
    width: 230px;
    border-right: 1px solid lightgray;
    padding: 20px 10px;
    min-height: 60vh;
    overflow-x: hidden;
}

.style_right__232_h {
    width: calc(100% - 230px);
    /* padding: 30px; */
}

.style_docContainer__1G_pg {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.style_DCard__3qy91 {
    box-shadow: 0px 4px 4px 0px #0000000D;
    border-radius: 15px;
    padding: 25px;
    background: linear-gradient(180deg, rgba(255, 199, 181, 0.3) 0%, rgba(255, 183, 199, 0.3) 52.49%, rgba(255, 179, 204, 0.3) 100%);
    position: relative;
    overflow: hidden;
}

.style_dUpper__2wrSb {
    height: 140px;
}


.style_dbottom__37R-E {
    width: 100%;
    height: 60px;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    background: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.style_filetype__1wJ-d {
    width: 30px;
    margin-right: 10px;
}



@media screen and (max-width: 767px) {
    .style_tabContainer__qpnzw {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .style_left__1FBL8 {
        width: 100%;
        border-right: none;
        padding: 0;
        min-height: unset;
        overflow-x: auto;
    }

    .style_right__232_h {
        width: 100%;
    }

    .style_docContainer__1G_pg {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 15px;
        gap: 15px;
    }

    .style_dUpper__2wrSb {
        height: 120px;
    }

}

@media screen and (max-width: 576px) {
    .style_left__1FBL8 {
        font-size: 14px;
    }

    .style_profileContainer__3nd5a {
        border: 3px solid var(--primaryColor);
        width: 100px;
        height: 100px;
        border-radius: 100%;
        overflow: hidden;
        /* box-shadow: inset 0px 0px 0px 7px var(--primaryColor); */
        z-index: 2;
    }

    .style_camera__1mGQm {
        width: 25px;
        height: 25px;
        background: var(--primaryFillColor);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        position: absolute;
        right: 5px;
        bottom: 0px;
        cursor: pointer;
    }
}
input[type="file"]::-webkit-file-upload-button {
    display: none !important;
}

input[type="file"]::file-selector-button {
    display: none !important;
}

.agerestrict,
.categoryrow {
    margin-top: 20px !important;
}

.cancelButton {
    border: none;
    background-color: transparent;
    margin: auto 30px;
}

/* The switch - the box around the slider */
.switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 2em;
    transform: scale(0.7);
    margin-right: 10px;
    margin-left: -10px;
}


/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #B0B0B0;
    border: 1px solid #B0B0B0;
    transition: .4s;
    border-radius: 32px;
    outline: none;
}

.slider:before {
    position: absolute;
    content: "";
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    outline: 2px solid #B0B0B0;
    left: -1px;
    bottom: -1px;
    background-color: #fff;
    transition: transform .25s ease-in-out 0s;
}

.channelLogo {
    max-height: 35px !important;
}

.slider-icon {
    opacity: 0;
    height: 12px;
    width: 12px;
    stroke-width: 8;
    position: absolute;
    z-index: 999;
    stroke: var(--primaryColor);
    right: 60%;
    top: 30%;
    transition: right ease-in-out .3s, opacity ease-in-out .15s;
}


.form-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #344357;
    margin-bottom: 0.5rem;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin: 0;
    overflow: hidden;
    opacity: 0;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .75rem);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
}
.style_mainContainer__1OZhp {
    width: 100%;
    height: 100vh;
    overflow: auto;
    position: relative;
}

.style_snackbar__16482 {
    width: auto;
    /* min-width: 200px; */
    height: 50px;
    position: absolute;
    top: 50px;
    right: 20px;
    z-index: 99999;
    background: white;
    border-radius: 8px;
    display: flex;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    font-weight: 500;
}


.style_content__3qyRC {
    width: auto;
    /* white-space: nowrap; */
    padding: 14px 15px;
    font-size: 0.875rem;
}

.style_closeIcon__1NeQW {
    width: 30px;
    text-align: center;
    cursor: pointer;
}

.style_green__pv6cd {
    background: green;
    color: white;
}

.style_red__2oYqw {
    background: red;
    color: white;
}

.style_yellow__3aVU_ {
    background: gray;
    color: white;
}
